import { Component, OnInit } from '@angular/core';
import {CoinsearchService} from '../coinsearch.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-coinsorter',
  templateUrl: './coinsorter.component.html',
  styleUrls: ['./coinsorter.component.css']
})
export class CoinsorterComponent implements OnInit {
  public imagePath1='';
  public imgURL1: any= null;
  public message1: string='';
  public file1: File=new File([],'');

  public imagePath2='';
  public imgURL2: any= null;
  public message2: string='';
  public file2: File=new File([],'');

  public isWait = false;
  public resultData = {};
  public externalImagesist=[];
  private paramMapSubscription: Subscription=new Subscription();

  constructor(private _coinSearchService: CoinsearchService,private route: ActivatedRoute) { } 

  preview(files:any, tag: string) {
    console.log(files);
    console.log(tag);
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message1 = 'Only PNG images are supported.';
      return;
    }
    console.log('next2');

    const reader = new FileReader();

    if (tag === '1') {
      console.log('next.1');
      this.imagePath1 = files;
      
      reader.onload = (_event) => {
        this.imgURL1 = reader.result;
      };
      reader.readAsDataURL(files[0]);
      this.file1=files[0];
    }
    if (tag === '2') {
      console.log('next.2');
      //console.log(files);
      this.imagePath2 = files;
     
      reader.onload = (_event) => {
        this.imgURL2 = reader.result;

      };
      reader.readAsDataURL(files[0]);
      this.file2=files[0];
    }
  }



  async loadExternal(url: string, tag: string): Promise<File[]> {
    const response = await fetch(url);
    const data = await response.blob();
    const file = new File([data], tag, { type: data.type });
    this.preview([file], tag); // TODO:
    return [file];
  }

  rednerResult(data: any) {
    this.isWait=false;
    console.log(data);
    this.resultData=data;
  }

  onSubmit(): void {
    this.isWait = true;

    // this, this._coinSearchService.sorterProcess(this.imgURL1, this.imgURL2)
    //   .subscribe(
    //     data => this.rednerResult(data),
    //     error => console.error('Error!', error)
    //   );
      this, this._coinSearchService.sorterProcess2(this.file1, this.file2)
      .subscribe(
        data => this.rednerResult(data),
        error => console.error('Error!', error)
      );
  }  


  ngOnInit(): void {
    this.paramMapSubscription = this.route.queryParamMap.subscribe(queryParams => {
      if (queryParams.has('images')) {
        const imagesParam = queryParams.get('images');
        try {
          if (imagesParam){
            this.externalImagesist = JSON.parse(decodeURIComponent(imagesParam));
            if (this.externalImagesist.length==2){
              this.loadExternal(this.externalImagesist[0], '1');
              this.loadExternal(this.externalImagesist[1], '2');
            }
          }
        } catch (error) {
          console.error('Failed to parse images parameter:', error);
        }
      }
    });


  }
  ngOnDestroy(): void {
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
  }
}
