import { NONE_TYPE } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import {CoinsearchService} from '../coinsearch.service';


@Component({
  selector: 'app-scantaskinsert',
  templateUrl: './scantaskinsert.component.html',
  styleUrls: ['./scantaskinsert.component.css']
})
export class ScantaskinsertComponent implements OnInit {
  public imagePath1='';
  public imgURL1: any= null;
  public message1: string='';

  public imagePath2=  '';
  public imgURL2: any= null;
  public message2: string='';
  public messageSubmit: string='';
  public isWait = false;
  public newTaskId:string='';
  public newTaskStatusData=null;
  public flipHorizontal=false;
  public flipVertical=false;
  public flipAutomatic=true;


  constructor(private _coinSearchService: CoinsearchService) { }

    onSubmit(): void {
      this.isWait = true;

      this._coinSearchService.insertScanTask(this.imgURL1, this.imgURL2,this.flipHorizontal,this.flipVertical,this.flipAutomatic)
        .subscribe(
          data => this.clearOnSubmit(data),
          error => console.error('Error!', error)
        );
    }

    clearOnSubmit(data:any){
      
      this.messageSubmit='Inserted Scan Task ['+data['taskid']+']';
      this.newTaskId=data['taskid'];
      this.isWait = false; 
      this.imgURL1=null;
      this.imgURL2=null;
    }

    updateStatus(data:any){
      console.log(data);
      this.newTaskStatusData=data;
    }

    getTaskStatus(){
      this._coinSearchService.getScanTaskStatus(this.newTaskId).subscribe(
        data => this.updateStatus(data),
        error => console.error('Error!', error)
      );
    }

    preview(files:any, tag:string) {
      console.log(tag);
      if (files.length === 0) {
        return;
      }


    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message1 = 'Only images are supported.';
      return;
    }


      const reader = new FileReader();

      if (tag === '1') {
        console.log(files);
        this.imagePath1 = files;
        
        reader.onload = (_event) => {
          this.imgURL1 = reader.result;
        };
        reader.readAsDataURL(files[0]);
      }
      if (tag === '2') {
        console.log(files);
        this.imagePath2 = files;
      
        reader.onload = (_event) => {
          this.imgURL2 = reader.result;

        };
        reader.readAsDataURL(files[0]);
      }
  }
  

  ngOnInit(): void {

  }

}
