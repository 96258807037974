import { Component, OnInit } from '@angular/core';
import { CoinsearchService,RCUserProfile } from '../coinsearch.service';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';


@Component({
  selector: 'maintoolbar',
  templateUrl: './maintoolbar.component.html',
  styleUrls: ['./maintoolbar.component.css']
})
export class MaintoolbarComponent implements OnInit {

  public rcUserProfile      : RCUserProfile=new RCUserProfile();
  public isloggedIn : boolean = false;
  public isadmin : boolean = false;
  public avatarInitials :any = null;
  constructor(private readonly keycloak: KeycloakService,private _coinSearchService: CoinsearchService, private router: Router) { 
    this._coinSearchService.getRCUserProfile().then(response => {
      if (response!=null){
        this.rcUserProfile=response;
      }
    })
  }
  public showMessageWindow = false;
  public message = '';

  showMessage(msg:string) {
    this.showMessageWindow = true;
    this.message = msg;

    setTimeout(() => {
      this.showMessageWindow = false;
      this.message = '';
    }, 2000);
  }
  public initSession() {
    this.keycloak.login();
  }

  public closeSession() {
    this.keycloak.logout();
  }

  public copyExternalFiles() {
    this.showMessage('External files copy started...');
    console.log("copyExternalFiles");
    this._coinSearchService.copyExternalFiles().subscribe(
      result => {
        this.showMessage('External files copied!');
        
        },
      error => console.error('Error!', error)
    );
  }


  ngOnInit(): void {
    this.keycloak.isLoggedIn().then((loggedIn) => {
      if (!loggedIn) {
        this.router.navigate(['/']);
      }
    });

  }
  

}
