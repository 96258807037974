<mat-toolbar>
  <div class="main-header-row">
    <div class="row-container">
      <div class="logo">
        <img src="assets/img/logo2.png" alt="" />
      </div>
      <nav class="nav">
        <ul *ngIf="rcUserProfile.isLoggedIn">
          <li>
            <a
              mat-button
              class="gap"
              routerLink="/coinscans"
              routerLinkActive="active"
              >Coin Scans</a
            >
          </li>
          <li>
            <a
              mat-button
              class="gap"
              routerLink="/sorterlist"
              routerLinkActive="active"
              >SORTER LIST</a
            >
          </li>
          <li>
            <a
              mat-button
              *ngIf="rcUserProfile.isAdmin"
              class="gap"
              routerLink="/dbstatus"
              routerLinkActive="active"
              >Coin Catalog</a
            >
          </li>
          <!-- <li><a mat-button class = "gap" routerLink="/coinmatch">Coin Match</a></li> -->
          <!-- <li><a mat-button class = "gap" routerLink="/scantaskinsert">+ Scan Tasks</a></li> -->
          <li>
            <a
              mat-button
              class="gap"
              routerLink="/coinsorter"
              routerLinkActive="active"
              >+ SORTER</a
            >
          </li>
          <li>
            <a
              mat-button
              *ngIf="rcUserProfile.isAdmin"
              class="gap"
              routerLink="/templates"
              routerLinkActive="active"
              >Templates</a
            >
          </li>
          <li>
            <a
              mat-button
              *ngIf="rcUserProfile.isAdmin"
              class="gap"
              routerLink="/cointests"
              routerLinkActive="active"
              >Coin Tests</a
            >
          </li>
          <!-- <li><a mat-button class = "gap" routerLink="/scantasks">Scan Tasks</a></li> -->
          <!-- <li><a mat-button (click)="copyExternalFiles()">UplodDB!</a>  </li> -->
          <li>
            <a
              mat-button
              class="gap"
              routerLink="/sorterrules"
              routerLinkActive="active"
              >Sorter Rules</a
            >
          </li>
          <li>
            <a
              mat-button
              *ngIf="rcUserProfile.isAdmin"
              class="gap"
              routerLink="/coinscansvalue"
              routerLinkActive="active"
              >Coin Scans Value</a
            >
          </li>
          <li>
            <a
              mat-button
              class="gap"
              onClick="window.open('//keycloak.robocoin.ai:8080/auth/realms/robocoin/device')"
              routerLinkActive="active"
              >Connect Scanner</a
            >
          </li>
        </ul>
      </nav>
      <div *ngIf="rcUserProfile.isLoggedIn">
        <!-- <div class="avatar">
              <span>RC</span>
              </div> -->
      </div>

      <div class="login-box logout">
        <div
          *ngIf="rcUserProfile.isLoggedIn"
          class="login-btn"
          (click)="closeSession()"
        >
          <span>{{ rcUserProfile.userName }} </span>
          <img class="user-img" src="/assets/img/user-profile.png" alt="user" />
          <span class="divider"></span>
          <div class="logout-icon-wrapper">
            <mat-icon class="logout-icon">logout</mat-icon>
          </div>
        </div>
        <div
          *ngIf="!rcUserProfile.isLoggedIn"
          class="login-btn"
          (click)="initSession()"
        >
          login
        </div>
      </div>
    </div>

    <div class="message-window" *ngIf="showMessageWindow">{{ message }}</div>
  </div>
</mat-toolbar>
