import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CoinsearchFormComponent } from './coinsearch-form/coinsearch-form.component'
import { MaintoolbarComponent } from './maintoolbar/maintoolbar.component'
import { CoindbstatusComponent } from './coindbstatus/coindbstatus.component'
import { CoinmatchComponent } from './coinmatch/coinmatch.component';
import { CoinviewComponent } from './coinview/coinview.component';
import { TemplatesComponent } from './templates/templates.component';
import { TemplateviewComponent } from './templateview/templateview.component';
import { CoinscansComponent } from './coinscans/coinscans.component';
import { MobilematchComponent } from './mobilematch/mobilematch.component';
import { CoinscanviewComponent } from './coinscanview/coinscanview.component';
import { CointestsComponent } from './cointests/cointests.component';
import { ScantasksComponent } from './scantasks/scantasks.component';
import { ScantaskinsertComponent } from './scantaskinsert/scantaskinsert.component';
import { CoinsorterComponent } from './coinsorter/coinsorter.component';
import { SorterlistComponent } from './sorterlist/sorterlist.component';
import { CoinqualityComponent } from './coinquality/coinquality.component';
import { ScanTaskViewComponent } from './scan-task-view/scan-task-view.component';
import { CoinscansvalueComponent} from './coinscansvalue/coinscansvalue.component';
import { SorterrulesComponent } from './sorterrules/sorterrules.component';

const routes: Routes = [
  {path:'search',component: CoinsearchFormComponent},
  {path:'search/:qcat',component: CoinsearchFormComponent},
  {path:'coinview/:crid',component: CoinviewComponent},
  {path:'dbstatus',component: CoindbstatusComponent},
  {path:'coinmatch',component: CoinmatchComponent},
  {path:'coinmatch/:csid',component:CoinmatchComponent},
  {path:'templates',component: TemplatesComponent},
  {path:'templateview/:tid',component: TemplateviewComponent},
  {path:'coinscans',component: CoinscansComponent},
  {path:'coinscansvalue',component: CoinscansvalueComponent},
  {path:'mobilematch',component:MobilematchComponent},
  {path:'coinscanview/:csid',component:CoinscanviewComponent},
  {path:'cointests',component:CointestsComponent},
  {path:'scantasks',component:ScantasksComponent},
  {path:'scantaskinsert',component:ScantaskinsertComponent},
  {path:'coinsorter',component:CoinsorterComponent},
  {path:'sorterlist',component:SorterlistComponent},
  {path:'sorterlist/:tid',component:SorterlistComponent},
  {path:'coinquality/:testid',component:CoinqualityComponent},
  {path:'scantaskview/:tid',component:ScanTaskViewComponent},
  {path:'sorterrules',component:SorterrulesComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [CoinsearchFormComponent,MaintoolbarComponent,CoindbstatusComponent,CoinmatchComponent,
  CoinviewComponent,CoinscansComponent,MobilematchComponent,CoinscanviewComponent,CointestsComponent,ScantasksComponent,
  ScantaskinsertComponent,CoinsorterComponent,SorterlistComponent,CoinqualityComponent,ScanTaskViewComponent,CoinscansvalueComponent,
  SorterrulesComponent]
