export class CoinSearchData {
    constructor(
        public queryNID: string,
        public categoryID: string,
        public date: string,
        public mintSymbol: string,
        public showCollectors: boolean,
        
    ){}
}

export class CoinData {
    constructor(
        public title: string,
        public uidNGC: string,
        public diameter: string,
        public category: string,
        public localLink: string,
        public imageObverse: string,
        public imageReverse: string,
        public rcid: string,
        public priceInfo: any,
        public priceCategory: String,
        public sms: boolean,
        public proof:boolean,
    ){}

}

export class CoinList {
    constructor(){}
    public coins:Array<CoinData>=[];
}

