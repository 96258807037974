<div class="inner-content-wrap" *ngIf="coinData!=null">
  <table>
    <tr>
      <td>
        <div class="product-info-wrap">
            <div class="coins-inner-tbl-wrap">
                <table class="coins-info-tbl">
                    <tr>
                        <td>
                          <div class="iner-head">
                            <div class="inr-l">ID | Value | Diam | User:</div> 
                            <div class="inr-r">{{ coinData['customid'] }} <span class="dot"></span> {{ coinData['value'] }} <span class="dot"></span> {{ coinData['diameter'] }} <span class="dot"></span> {{ coinData['ownername'] }}</div>
                            </div>
                          </td>
                        <td>
                          <div class="iner-head">
                            <div class="inr-l">Scan Date:</div>  
                            {{ coinTimeStamp }}
                          </div>
                      </td>
                        <td>
                          <div class="iner-head">
                            <div class="inr-l">Coin Features :</div>
                          <div class="coin-feature" *ngIf="coinFeaturesDef!=null">
                            <div class="cfset" *ngFor="let cf of coinFeaturesDef">
                              {{cf['name']}}:
                              <select class="feature-select" [ngModel]="featureList.get(cf['name'])" (ngModelChange)="featureList.set(cf['name'], $event)">
                                <option value="" >---remove---</option>
                                <option *ngFor="let item of cf['values']" [value]="item">{{item}}</option>
                              </select>
                            </div>
                        </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                        <td>
                          <div class="iner-head">
                          <div class="inr-l">Category Code <a [routerLink]="['/search', coinData['categoryCode']]"><img class="coin-catalogue" src="assets/img/coin_cat.png" alt="{{coinData['categoryCode']}}"></a></div>
                            <div class="inr-r">
                              <select [(ngModel)]="category" id="category">
                                <option value="" disabled selected>select a category</option>
                                <option *ngFor="let item of categories" [value]="item">{{item}}</option>
                              </select>
                        </div>
                            
                            </div>
                        </td>
                        <td>
                          <div class="iner-head">
                            <div class="inr-l">Verified:{{ coinData['verified'] }}</div>  
                            <div class="iner-head">
                              <div class="inr-l">Is Test Set:</div> 
                              <div class="inr-l"><input class="coin-test-set" type="checkbox" [disabled]="coinData['verified']" [(ngModel)]="isTestSet" id="isTestSet">
                              </div>
                              </div>
                            
                            
                          </div>
                        </td>
                        <td>
                          <div class="iner-head">
                            <div class="inr-l">Date: </div>
                            <div class="inr-r">

                              <input class ="coin-date-input" matInput name = "coindate"  type="number" required [(ngModel)]="coindate">
                              <button class="coin-date-btn"  (click)="changeDate(-10)">-10</button>
                              <button class="coin-date-btn"(click)="changeDate(10)">+10</button>
                          </div>
                          </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                          <div class="iner-head">
                          <div class="inr-l">Coin Quality</div>
                            <div class="inr-r">
                              <select [(ngModel)]="coinQuality" id="coinQuality">
                                <option value="">None</option>
                                <option value="A">category A</option>
                                <option value="B">category B</option>
                                <option value="C">category C</option>
                                <option value="D">category D</option>
                              </select>
                            </div>
                            
                            </div>
                        </td>
                        <td>
                          <div *ngIf="rulesValueIndicator!==null" class="iner-head">
                            <span class="inr-l">Value:</span>
                            <span *ngIf="rulesValueIndicator['level']!==null"> {{ rulesValueIndicator['level']}}</span>
                            <span *ngIf="rulesValueIndicator['description']!==null"> {{ rulesValueIndicator['description']}}</span>

                          </div>
                          </td> 
                        <td> 
                          <div class="iner-head">
                            <div class="inr-l">Mint Symbol:</div>
                            <div class="inr-r">
                        
                          <ul class="mint-symbol" name = "mintsymbol" >
                          <li>
                            <input type="radio" id="a25" name="mintsymbol" value="N"  [(ngModel)]="mintsymbol"/>
                            <label for="a25">N</label>
                          </li>
                          <li>
                            <input type="radio" id="a50" name="mintsymbol" value="P"  [(ngModel)]="mintsymbol"/>
                            <label for="a50">P</label>
                          </li>
                          <li>
                            <input type="radio" id="a75" name="mintsymbol" value="S"  [(ngModel)]="mintsymbol"/>
                            <label for="a75">S</label>
                          </li>
                          <li>
                            <input type="radio" id="a100" name="mintsymbol" value="W"  [(ngModel)]="mintsymbol"/>
                            <label for="a100">W</label>
                          </li>
                        
                          <li>
                              <input type="radio" id="a100" name="mintsymbol" value="D"  [(ngModel)]="mintsymbol"/>
                              <label for="a100">D</label>
                            </li>
                        </ul>
                            </div>
                            </div>
                      </td>
                    </tr>

                </table>
            </div>
        </div>
        <table width="{{2*imageSize}}">
          <tr>
            <td>
              <div class="coin-pivot">            
                obverse Angle: <span class="observe-angle">{{rotationAmount1}} | invert {{invertScans}}</span> 
                <span class="angle-buttons">
                    <button class="anglebtn" (click)="rotateImage(1,'right',90)">+90</button>
                    <button class="anglebtn" (click)="rotateImage(1,'right',10)">+10</button>
                    <button class="anglebtn" (click)="rotateImage(1,'right',1)">+1</button>
                    <button class="anglebtn" (click)="rotateImage(1,'left',1)">-1</button>
                    <button class="anglebtn" (click)="rotateImage(1,'left',10)">-10</button>
                    <button class="anglebtn" (click)="rotateImage(1,'left',90)">-90</button>
                </span>
              </div>
            </td>
            <td>
              <div class="coin-pivot">  
                reverse angle: <span class="reverse-angle">{{rotationAmount2}} | invert {{invertScans}}</span>
                <span class="angle-buttons">
                  <button class="anglebtn" (click)="rotateImage(2,'right',90)">+90</button>
                  <button class="anglebtn" (click)="rotateImage(2,'right',10)">+10</button>
                  <button class="anglebtn" (click)="rotateImage(2,'right',1)">+1</button>
                  <button class="anglebtn" (click)="rotateImage(2,'left',1)">-1</button>
                  <button class="anglebtn" (click)="rotateImage(2,'left',10)">-10</button>
                  <button class="anglebtn" (click)="rotateImage(2,'left',90)">-90</button>
                </span>
            </div> 
            </td>
          </tr>

          <tr>
            <td >
              <div>
                <figure class="coinimage">
                    <!-- For imageObverse -->
                    <div class="img-magnifier-container">
                      <img #imgObverse *ngIf="imageObverse!==null" width="{{imageSize}}" class="image" [ngStyle]="{'transform': 'rotate(-'+ rotationAmount1+'deg)'}" [src]="imageObverse" />
                      <div class="img-magnifier-lens" #lens1></div>
                      <div *ngIf="showGrid && imageObverse!==null" class="grid-overlay"></div>
                    </div>


                      <!-- <img *ngIf="imageObverse===null" width="{{imageSize}}" [src]="'assets/img/nofoto.png'" />
                      <div *ngIf="invertScans===false" class="coin-image-scan">
                          <img *ngIf="imageObverse!==null" width="{{imageSize}}" class="image" [ngStyle]="{'transform': 'rotate(-'+ rotationAmount1+'deg)'}" [src]="imageObverse"  />
                          <div class="magnifier"></div>
                      </div>
                      <div *ngIf="invertScans===true" class="coin-image-scan">
                          <img *ngIf="imageReverse!==null" width="{{imageSize}}"  class="image" [ngStyle]="{'transform': 'rotate(-'+ rotationAmount1+'deg)'}" [src]="imageReverse"  />
                      </div>
                      <div class="grid" *ngIf="showGrid && imageObverse!==null">
                        <img width="{{imageSize}}" src="assets/img/grid.png" />
                      </div> -->

                </figure>
            </div>
            </td>
            <td >
              <div >
                <figure>

                  <!-- For imageReverse -->
                  <div class="img-magnifier-container">
                    <img #imgReverse *ngIf="imageReverse!==null" width="{{imageSize}}" class="image" [ngStyle]="{'transform': 'rotate(-'+ rotationAmount2+'deg)'}" [src]="imageReverse" />
                    <div class="img-magnifier-lens" #lens2></div>
                    <div *ngIf="showGrid && imageObverse!==null" class="grid-overlay"></div>
                  </div>
                    <!-- <img *ngIf="imageReverse===null" width="{{imageSize}}" [src]="'assets/img/nofoto.png'" />
                    <div *ngIf="invertScans===false" class="coin-image-scan">
                        <img *ngIf="imageReverse!==null" width="{{imageSize}}"  class="image" [ngStyle]="{'transform': 'rotate(-'+ rotationAmount2+'deg)'}" [src]="imageReverse"  />
                    </div>
                    <div *ngIf="invertScans===true" class="coin-image-scan">
                        <img *ngIf="imageReverse!==null" width="{{imageSize}}"  class="image" [ngStyle]="{'transform': 'rotate(-'+ rotationAmount2+'deg)'}" [src]="imageObverse"  />
                    </div>
                    <div class="grid" *ngIf="showGrid && imageReverse!==null">
                      <img width="{{imageSize}}"  src="assets/img/grid.png" />
                  </div> -->
                </figure>
            </div>
            </td>
          </tr>
        </table>
      </td>
      <td>
        <div class="product-info-wrap product-info-wrap-buttons">
            <div class="coin-actions-wrap">
                <div class="coin-action-btns">
                    <a class="btn btn-prime btn-s" (click)="invertoinScans()">Invert scan</a>
                    <a class="btn btn-prime btn-s" (click)="toggleGrid()">Toggle grid</a>
                    <a class="btn btn-prime btn-s" (click)="deleteCoin()">Delete coin</a>
                    <a class="btn btn-prime btn-s" (click)="updateCoin()">Save coin</a>
                    <a class="btn btn-prime btn-s" (click)="nextScanToVerify()">Next scan to verify</a>
                    <!-- <a class="btn btn-prime btn-s" [routerLink]="['/coinmatch', coinData['customid']]">Match coin</a> -->
                    <a class="btn btn-prime btn-s" (click)="updateCoin(true)">Save /Next scan to verify</a>
                    <a class="btn btn-prime btn-s" (click)="exportCoinScanImage()">Export Image</a>

                </div>

            </div>
        </div>
      </td>
  </tr>
  </table>